import getConfig from "@/services/getConfig";
import { getField, updateField } from "vuex-map-fields";
import { SUPPORTED_PACKAGE_TYPE, PAYMENT_PROVIDER_OMISE } from "@/lib/constant";
import { isEmpty } from "lodash-es";
import * as alert from "@/lib/alert";
import { config as useConfig } from "@/composable/webConfig";
import { isDesktop } from "@/helper/screenSizeHelper";
const initialState = {
  readyRenderFooter: false,
};

export default {
  namespaced: true,
  state: {
    config: {},
    allowSearchByCity: false,
    restaurantReview: {
      showSection: true,
    },
    openLoginModal: false,
    subscribeMarketingReward: "฿50",
    renderNavbar: true,
    renderFooter: true,
    useCheckoutFrame: true,
    apiDomain: "",
    subWebDomain: "",
    nuxtDomain: "",
    pegasusDomain: "",
    idDomain: "",
    labelDomain: "",
    feature: "",
    newStorePageIds: [],
    ...initialState,
  },
  getters: {
    getField,
    paymentProvider(state) {
      if (state.config.selectedPaymentProvider) {
        return (
          state.config.selectedPaymentProvider.cc || PAYMENT_PROVIDER_OMISE
        );
      }
      return "";
    },
    availableCities(state) {
      return (
        state.config?.cities || [
          {
            id: 1,
            name: "Bangkok",
          },
        ]
      );
    },
    packageList(state) {
      if (!isEmpty(state.config)) {
        const packageListObj = state.config.packageList;
        // convert object of package list from server to array
        let array = [];
        for (const prop in packageListObj) {
          // only push package type that supported in web app
          if (SUPPORTED_PACKAGE_TYPE.includes(prop)) {
            array.push({
              key: prop,
              val: packageListObj[prop],
            });
          }
        }
        return array;
      }
      return [];
    },
    referrerRewardPoint(state) {
      return state.config?.dynamicPoints?.referrerRewardPoint || 0;
    },
    earlyReviewPoint(state) {
      return state.config?.dynamicPoints?.earlyBird?.point || 0;
    },
    earlyMaxReview(state) {
      return state.config?.dynamicPoints?.earlyBird?.maxReviews || 0;
    },
    isUsingAsyncBooking(state) {
      return state.config?.useAsyncBooking || false;
    },
    qrPaymentCountDown(state) {
      return state.config?.promptpayCountdown / 60 || 5;
    },
    voucherBanner(state) {
      return isDesktop
        ? state.config?.bannerVoucherUrl
        : state.config?.mobileBannerVoucherUrl;
    },
    voucherFeataureIsEnable(state) {
      return state.config?.enableGiftCardFeature || false;
    },
    enableFeatures(state) {
      let voucherInMarketplace = false;
      let firstAppVoucher = false;
      let enableSaveCc = false;
      let enableSaveAddress = false;
      let enableMapbox = true;
      let enableSearchByDtp = false;
      let enableStorePage = false;
      if (state.config && !isEmpty(state.config.features)) {
        const {
          voucherInMarketplace: allowVoucherMP,
          firstAppVoucher: allowFirstAppVoucher,
          enableSaveCc: allowSaveCc,
          enableSaveAddress: allowSaveAddress,
          enableMapbox: allowMapbox,
          enableSearchByDtp: allowSearchByDtp,
          enableStorePage: allowStorePage,
        } = state.config.features;
        voucherInMarketplace = allowVoucherMP;
        firstAppVoucher = allowFirstAppVoucher;
        enableSaveAddress = allowSaveAddress;
        enableSaveCc = allowSaveCc;
        enableMapbox = allowMapbox;
        enableSearchByDtp = allowSearchByDtp;
        enableStorePage = allowStorePage;
      }
      return {
        voucherInMarketplace,
        firstAppVoucher,
        enableSaveCc,
        enableSaveAddress,
        enableMapbox,
        enableSearchByDtp,
        enableStorePage,
      };
    },
    encryptedApiDomain(state) {
      return btoa(state.apiDomain);
    },
    isOnProduction() {
      return process.env.VUE_APP_MODE === "production";
    },
  },
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.state] = payload.val;
    },
    setEnvironment(state, payload) {
      const {
        apiDomain,
        nuxtDomain,
        subWebDomain,
        id,
        label,
        feature,
        pegasusDomain,
      } = payload;
      state.apiDomain = apiDomain || "";
      state.nuxtDomain = nuxtDomain || "";
      state.subWebDomain = subWebDomain || "";
      state.idDomain = id || "";
      state.labelDomain = label || "";
      state.feature = feature || "";
      state.pegasusDomain = pegasusDomain || "";
    },
  },
  actions: {
    async getConfig({ state }) {
      const result = await getConfig();
      if (result.isSuccess) {
        state.config = { ...state.config, ...result.data };
        useConfig.value = state.config;
        return;
      }
      if (result.message) {
        alert.error(result.message);
      }
    },
    async getNewStorePageRestaurantsList({ state }) {
      const { getNewStorePageRestaurantsList } = await import(
        "@/services/getNewStorePageRestaurants"
      );
      const { data, message, isSuccess } =
        await getNewStorePageRestaurantsList();
      state.newStorePageIds = data;
      if (!isSuccess) {
        alert.error(message);
      }
    },
    toggleClevertapPopup(ctx, isShow = false) {
      const clevertapSelector = "#wizParDiv2";
      const el = document.querySelector(clevertapSelector);
      if (el) {
        el.style.display = isShow ? "block" : "none";
      }
    },
    toggleLoginModal({ state }, isShow) {
      if (typeof isShow !== "boolean") {
        throw new Error("Failed toggleLoginModal, invalid 'isShow' value");
      }
      state.openLoginModal = isShow;
    },
    setReadyRenderFooter({ commit }, value) {
      commit("setState", {
        state: "readyRenderFooter",
        val: value,
      });
    },
  },
};
