import { computed, ref } from "@vue/composition-api";
import { useStorage, useCssVar } from "@vueuse/core";
import { PARTNER_OPENRICE, PARTNER_AOA, PARTNER_TAGTHAI } from "@/lib/constant";
import { Partner } from "@/types/Partner";

const defaultPartner: Partner = {
  name: "",
  layout: "",
  token: "",
  logo: "",
  color: {
    primary: "",
    primaryLight: "",
    primaryPink: "",
    primaryFilter: "",
  },
  data: null,
  features: {
    enableVoucher: true,
    enableOffers: true,
    enableGiftCard: true,
    enableBreadcrumbs: true,
    enableFavorites: true,
    enableMobileNavigation: true,
    enableDelivery: false,
  },
};

const hhPartners = [PARTNER_OPENRICE, PARTNER_AOA, PARTNER_TAGTHAI];

const partner = useStorage("hungryhub_partner", defaultPartner);

function usePartner() {
  const selectedPartner = ref<Partner>();
  const isPartner = computed(() => {
    return hhPartners.some((p) => p === partner.value?.name);
  });
  const layout = computed(() => {
    return partner.value.layout;
  });
  const isPartnerTagThai = computed(() => {
    return partner.value.name === PARTNER_TAGTHAI;
  });

  const primaryColor = useCssVar("--primary-color");
  primaryColor.value = partner.value?.color?.primary;
  const primaryLightColor = useCssVar("--primary-color-light");
  primaryLightColor.value = partner.value?.color?.primaryLight;
  const primaryPinkColor = useCssVar("--primary-color-pink");
  primaryPinkColor.value = partner.value?.color?.primaryPink;
  const primaryFilterColor = useCssVar("--primary-color-filter");
  primaryFilterColor.value = partner.value?.color?.primaryFilter;

  const initPartner = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const partnerName = urlParams.get("partner") || "";
    const partnerToken = urlParams.get("token") || "";
    const partnerLayout = urlParams.get("layout") || "";

    if (!partnerName) {
      // Reset partner to default due to OpenRice not embedding the Partner WebView on Web Desktop.
      if (partner.value.name === PARTNER_OPENRICE) {
        partner.value = defaultPartner;
      }
      return;
    }
    const currentPartner = hhPartners.find((p) => p === partnerName);
    if (!currentPartner) return;

    switch (currentPartner) {
      case PARTNER_AOA: {
        const { aoaPartner } = await import("@/services/Partner/AoaPartner");
        selectedPartner.value = aoaPartner;
        break;
      }
      case PARTNER_OPENRICE: {
        const { openRicePartner } = await import(
          "@/services/Partner/OpenRicePartner"
        );
        selectedPartner.value = openRicePartner;
        break;
      }
      case PARTNER_TAGTHAI: {
        const { tagthaiPartner, getTagThaiUserInfo } = await import(
          "@/services/Partner/TagthaiPartner"
        );
        selectedPartner.value = tagthaiPartner;
        const { isSuccess, data } = await getTagThaiUserInfo(
          partnerName,
          partnerToken
        );
        selectedPartner.value.data = isSuccess ? data : null;
        break;
      }
      default:
        selectedPartner.value = defaultPartner;
        break;
    }
    partner.value = {
      ...selectedPartner.value,
      token: partnerToken,
      layout: partnerLayout || selectedPartner.value.layout,
    };
  };

  return {
    partner,
    isPartner,
    layout,
    initPartner,
    isPartnerTagThai,
  };
}

export { usePartner };
